import React, { useEffect } from 'react';

import { Button, Collapse, Spin } from 'antd';
import { useQuery } from 'react-query';

import CSVService from '../../../APIServices/CSVService';
import { LogType } from '../../../models/LogType';
import { convertDate } from '../../../utils/ConvertDate';
import { lastUploadedFileSummary } from '../../../utils/helpers';
import type { UploaderTabs } from '../../../utils/types';

type PROPS = {
  dragable: React.ReactNode;
  currentStep: number;
  inProgress: boolean;
  next: () => void;
  prev: () => void;
  uploadSuccess: boolean | null;
  downloadSummaryFile: (log: LogType, fileName: string) => void;
};

const tabs: UploaderTabs[] = [
  {
    title: 'Coverage Map',
    fileType: 'ServiceCoverage',
  },
  {
    title: 'Customer Service Map',
    fileType: 'AssetClassServiceMap',
  },
  {
    title: 'Customer Site Data',
    fileType: 'customer_site',
  },
  {
    title: 'Base Asset',
    fileType: 'asset_ib_extract',
  },
  {
    title: 'Geo Tag Data',
    fileType: 'asset_geotag_scan',
  },
  {
    title: 'CFS/MCN Data Extract',
    fileType: 'service_contract',
  },
];

const CollapsibleHeader = ({
  activeTab,
  id,
  inProgress,
  uploadSuccess,
}: {
  activeTab: string;
  id: string;
  inProgress: boolean;
  uploadSuccess: boolean | null;
}) => {
  if (inProgress && uploadSuccess === null && activeTab === id) {
    return <Spin size="small" className="self-center" />;
  }
  if (!inProgress && uploadSuccess === null && activeTab === id) {
    return (
      <span className="rounded-full h-6 w-6 text-xs bg-ncr-brand-dark-teal text-white p-1 text-center">
        {id}
      </span>
    );
  }
  if (!inProgress && !uploadSuccess && activeTab === id) {
    return (
      <span className="rounded-full h-6 w-6 text-xs bg-red-500 text-white p-1 text-center">
        x
      </span>
    );
  }
  if (!inProgress && uploadSuccess && activeTab === id) {
    return (
      <span className="rounded-full h-6 w-6 text-xs bg-ncr-brand-dark-teal text-white p-1 text-center">
        ✓
      </span>
    );
  }
  return (
    <span className="rounded-full h-6 w-6 text-xs text-gray-300 border border-solid !border-gray-300 p-1 text-center">
      {id}
    </span>
  );
};

const Uploader = ({
  dragable,
  currentStep,
  inProgress,
  next,
  prev,
  uploadSuccess,
  downloadSummaryFile,
}: PROPS) => {
  const { data: latestUploads, refetch: refetchLatestUploads } = useQuery(
    ['last-latest-uploads'],
    () => CSVService.getLastUploadLogs()
  );

  const [activeTab, setActiveTab] = React.useState(['1']);

  const handleNext = () => {
    setActiveTab([String(currentStep + 1 + 1)]);
    next();
  };
  const handlePrev = () => {
    setActiveTab([String(currentStep + 1 - 1)]);
    prev();
  };

  const enableNext = () => {
    if (!inProgress && uploadSuccess) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (uploadSuccess) {
      refetchLatestUploads();
    }
  }, [uploadSuccess]);

  return (
    <>
      <Collapse
        ghost
        defaultActiveKey={activeTab}
        accordion
        expandIconPosition="end"
        activeKey={activeTab}
        className="sliamuploader-collapse"
      >
        {tabs.map(({ title, fileType }, i) => {
          const id = String(i + 1);
          const fileSummary = lastUploadedFileSummary(
            fileType,
            latestUploads?.data
          );
          const fileRecord = latestUploads?.data.find(
            (lp: LogType) => lp.fileType === fileType
          );
          return (
            <Collapse.Panel
              collapsible={currentStep === i ? 'header' : 'disabled'}
              header={
                <div className="flex justify-start space-x-2">
                  <CollapsibleHeader
                    activeTab={activeTab[0]}
                    id={id}
                    inProgress={inProgress}
                    uploadSuccess={uploadSuccess}
                  />

                  <div className="flex flex-col self-center">
                    <span className="self-start">{title}</span>
                    <div className="self-start text-xs flex space-x-1">
                      {!fileSummary.summaryFile && !fileSummary.uploadedAt && (
                        <span className="text-black/25">No file uploaded</span>
                      )}
                      {fileSummary.summaryFile && fileSummary.uploadedAt && (
                        <>
                          {currentStep === i && (
                            <>
                              <Button
                                type="link"
                                className="self-center text-xs p-0"
                                disabled={currentStep !== i}
                                onClick={() =>
                                  downloadSummaryFile(
                                    fileRecord,
                                    fileSummary.summaryFile as string
                                  )
                                }
                              >
                                Download
                              </Button>
                              <span
                                className={`rounded-full ${
                                  currentStep === i
                                    ? `bg-ncr-brand-dark-teal`
                                    : `bg-black/25`
                                }  h-1 w-1 self-center`}
                              >
                                &nbsp;
                              </span>
                            </>
                          )}
                          <span
                            className={`self-center ${
                              currentStep === i
                                ? `text-ncr-brand-dark-teal`
                                : `text-black/25`
                            }`}
                          >
                            Last Uploaded: {convertDate(fileSummary.uploadedAt)}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              }
              key={id}
            >
              {dragable}
            </Collapse.Panel>
          );
        })}
      </Collapse>
      <br />
      <div
        className={`flex ${
          currentStep === 0 ? 'justify-end' : 'justify-between'
        } `}
      >
        {currentStep > 0 && (
          <Button
            type="default"
            loading={inProgress}
            style={{ margin: '0 8px' }}
            onClick={handlePrev}
          >
            Previous
          </Button>
        )}
        {currentStep >= 0 && currentStep < 5 ? (
          <Button
            type="primary"
            loading={inProgress}
            onClick={handleNext}
            disabled={enableNext()}
          >
            Next
          </Button>
        ) : null}
      </div>
    </>
  );
};

export default Uploader;
