import React, { useEffect, useState } from 'react';

import { CloseCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Row,
  Col,
  Card,
  Space,
  Table,
  Tag,
  Tooltip,
  Input,
  Select,
  UploadProps,
  message,
  Empty,
  notification,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useQueryClient, useQuery } from 'react-query';

import CSVService from '../../../APIServices/CSVService';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { LogType } from '../../../models/LogType';
import {
  convertDate,
  convertMillisecondsToDHMS,
} from '../../../utils/ConvertDate';

interface UploaderProps {
  tab?: string;
}

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const CSVUploads: React.FC<UploaderProps> = ({ tab }) => {
  const [uploadFileType, setUploadFileType] = React.useState('Select Field');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();

  const [currentUploads, setCurrentUploads] = useState<any>([]);
  const [filters, setFilters] = useState<any>('');
  const [searchField, setSearchField] = useState('Select Field');
  const [searchFieldSub, setSearchFieldSub] = useState('Select Field');
  const [api, contextHolder] = notification.useNotification();
  const [isSubField, setIsSubField] = useState(false);
  const [selectSearchTerms, setSelectSearchTerms] = useState<string>('');
  const [disableSearch, setDisableSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { Search } = Input;
  const { Option } = Select;

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description,
      duration: 20,
    });
  };

  const downloadSummaryFile = (log: LogType, fileName: string) => {
    axiosPrivate({
      method: 'GET',
      url: `/impex/import/logs/${log.id}/summaryfile`,
      responseType: 'blob',
    })
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName); // any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
        openNotificationWithIcon('success', 'Success', 'Download successful.');
      })
      .catch((error) => {
        openNotificationWithIcon(
          'error',
          'Error',
          'Download failed, some files may be archived and not available for download anymore.'
        );
        console.log(error, 'error');
      });
  };

  const searchFields = [
    { id: 'progressStatus', value: 'Progress Status' },
    { id: 'fileType', value: 'File Type' },
    // { id: 'srcFile', value: 'File' },
  ];

  const [statusDropdowns, setStatusDropdowns] = useState([
    { id: 'FilePersisted', value: 'Accepted' },
    { id: 'NonStarted', value: 'Non Started' },
    { id: 'InProgress', value: 'In Progress' },
    { id: 'Completed', value: 'Completed' },
    { id: 'Aborted', value: 'Aborted' },
    { id: 'Paused', value: 'Paused' },
  ]);

  const [typeDropdowns, setTypeDropdowns] = useState([
    { id: 'ServiceCoverage', value: 'Coverage Map' },
    { id: 'AssetClassServiceMap', value: 'Asset Class Service Map' },
    { id: 'customer_site', value: 'Customer Site' },
    { id: 'asset_ib_extract', value: 'Asset IB Extract' },
    { id: 'service_contract', value: 'Service Contract' },
    { id: 'asset_geotag_scan', value: 'Asset GeoTag Scan' },
  ]);

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    accept: '.csv, .xlsx',
    showUploadList: false,
    // action: `${process.env.REACT_APP_API_URL}impex/import/${uploadFileType}`,
    customRequest: (options: any) => {
      const formData = new FormData();
      formData.append('file', options.file);
      axiosPrivate({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}impex/import/${uploadFileType}`,
        data: formData,
      })
        .then((res) => {
          options.onSuccess(res, options.file);
        })
        .catch((err) => {
          options.onError(err, options.file);
        });
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList, 'uploading');
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        queryClient.clear();
        setCurrentUploads([
          ...currentUploads,
          { name: info.file.name, fileData: info.file.response.data },
        ]);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const {
    data: recentUploads,
    isLoading,
    refetch: recallRecentUploads,
  } = useQuery(['uploadHistory', page, pageSize, filters], () =>
    CSVService.getUploadLogs(page, pageSize, filters)
  );

  const downloadSourceFile = (log: LogType, fileName: string) => {
    axiosPrivate({
      method: 'GET',
      url: `/impex/import/logs/${log.id}/srcfile`,
      responseType: 'blob',
    })
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName); // any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
        openNotificationWithIcon('success', 'Success', 'Download successful.');
      })
      .catch((error) => {
        openNotificationWithIcon(
          'error',
          'Error',
          'Download failed, some files may be archived and not available for download anymore.'
        );
        console.log(error, 'error');
      });
  };

  const columns: ColumnsType<LogType> = [
    {
      title: 'Status',
      dataIndex: 'progressStatus',
      key: 'progressStatus',
      render: (_, record) => {
        let color = 'green';
        let text;
        if (record.progressStatus === 'FilePersisted') {
          color = 'slateBlue';
          text = 'Accepted';
        }
        if (record.progressStatus === 'NonStarted') {
          color = 'darkGray';
          text = 'Not Started';
        }
        if (record.progressStatus === 'InProgress') {
          color = 'gold';
          text = 'In Progress';
        }
        if (record.progressStatus === 'InProcessQueue') {
          color = 'blue';
          text = 'In Queue';
        }
        if (record.progressStatus === 'Completed') {
          color = 'green';
          text = 'Completed';
        }
        if (record.progressStatus === 'Aborted') {
          color = 'red';
          text = 'Aborted';
        }
        if (record.progressStatus === 'Failed') {
          color = 'red';
          text = 'Failed';
        }
        if (record.progressStatus === 'Paused') {
          color = 'orange';
          text = 'Paused';
        }
        return (
          <>
            {' '}
            <Tag color={color}>{text}</Tag>
            {record.failedCount > 0 && (
              <Tag color="red">{text} with Errors</Tag>
            )}
          </>
        );
      },
    },
    {
      title: 'File Name',
      dataIndex: 'srcFile',
      key: 'srcFile',
    },

    {
      title: 'Success Count',
      dataIndex: 'successCount',
      key: 'successCount',
      render: (record) => {
        return <Tag color="green">{record}</Tag>;
      },
    },
    {
      title: 'Failed Count',
      dataIndex: 'failedCount',
      key: 'failedCount',
      render: (record) => {
        return (
          <Tooltip title="To view failed records download execution summary">
            <Tag color="red">{record}</Tag>
          </Tooltip>
        );
      },
    },
    {
      title: 'Execution Time',
      dataIndex: 'executionTime',
      key: 'executionTime',
      render: (time) => {
        return (
          <Tag color="default">
            {time ? convertMillisecondsToDHMS(time) : 'Not Available'}
          </Tag>
        );
      },
    },
    {
      title: 'File Type',
      dataIndex: 'fileType',
      key: 'fileType',
      render: (fileType) => {
        if (fileType === 'service_contract') {
          return 'Service Contract';
        }
        if (fileType === 'asset_geotag_scan') {
          return 'Asset Geotag Scan';
        }
        if (fileType === 'asset_ib_extract') {
          return 'Asset IB Extract';
        }
        if (fileType === 'asset_ib_scan') {
          return 'Asset IB Scan';
        }
        if (fileType === 'AssetClassServiceMap') {
          return 'Asset Class Service Map';
        }
        if (fileType === 'customer_site') {
          return 'Customer Site';
        }
        return fileType;
      },
    },
    {
      title: 'Uploaded By',
      dataIndex: 'uploadedBy',
      key: 'uploadedBy',
    },
    {
      title: 'Uploaded',
      dataIndex: 'uploadedAt',
      key: 'uploadedAt',
      render: (time) => {
        return convertDate(time);
      },
    },

    {
      title: 'Source File',
      key: 'action',
      render: (_, record) => {
        if (record.progressStatus === 'Completed') {
          return (
            <Button
              size="small"
              type="primary"
              id={`download-source-file-${record.id}`}
              onClick={() => downloadSourceFile(record!, record.srcFile)}
            >
              Download
            </Button>
          );
        }
        return <>Not Currently Available</>;
      },
    },
    {
      title: 'Execution Summary',
      key: 'action',
      render: (_, record) => {
        if (
          record.progressStatus === 'Completed' ||
          record.progressStatus === 'Failed'
        ) {
          return (
            <Button
              size="small"
              type="primary"
              id={`download-summary-file-${record.id}`}
              onClick={() => downloadSummaryFile(record!, record.summaryFile)}
            >
              Download
            </Button>
          );
        }
        return <>Not Currently Available</>;
      },
    },
  ];

  const handleChange = (pagination: any) => {
    setPage(pagination.current - 1);
    setPageSize(pagination.pageSize);
  };

  const setSearchTerms = (value: string) => {
    setSearchFieldSub(value);
    if (searchField === 'progressStatus' || searchField === 'fileType') {
      setFilters(`${searchField}=${value}`);
    } else {
      setFilters(``);
    }
  };

  const setMainSearchTerms = (value: string) => {
    if (searchField === 'progressStatus' || searchField === 'fileType') {
      setFilters(value);
    }
    setSearchField(value);
    setSelectSearchTerms(value);
    setIsSubField(false);
    setSearchFieldSub('');
  };

  useEffect(() => {
    if (searchField === 'progressStatus' || searchField === 'fileType') {
      setIsSubField(true);
      setSearchFieldSub('Select Field');
      setDisableSearch(true);
      setSearchValue('');
    } else {
      setIsSubField(false);
      setSearchFieldSub('Select Field');
      setSelectSearchTerms('');
      setDisableSearch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchField]);

  const selectBefore = (
    <div>
      <Select
        defaultValue="Select Field"
        placeholder="Select Field"
        value={searchField}
        onChange={(e) => setMainSearchTerms(e)}
        style={{ width: 200 }}
        getPopupContainer={(trigger) => trigger.parentElement}
      >
        {searchFields?.map((item) => {
          return (
            <Option key={item.id} value={item.id}>
              {item.value}
            </Option>
          );
        })}
      </Select>
      {searchField === 'progressStatus' && (
        <>
          <Select
            defaultValue="Select Field"
            placeholder="Select Field"
            value={searchFieldSub}
            onChange={(e) => setSearchTerms(e)}
            style={{ width: 200 }}
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            {statusDropdowns?.map((item) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.value}
                </Option>
              );
            })}
          </Select>
        </>
      )}
      {searchField === 'fileType' && (
        <>
          <Select
            defaultValue="Select Field"
            placeholder="Select Field"
            value={searchFieldSub}
            onChange={(e) => setSearchTerms(e)}
            style={{ width: 200 }}
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            {typeDropdowns?.map((item) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.value}
                </Option>
              );
            })}
          </Select>
        </>
      )}
      {searchField === 'progressStatus' || searchField === 'fileType' ? (
        <Button
          type="primary"
          size="small"
          className="clear-button"
          onClick={() => {
            setSearchValue('');
            setFilters('');
            setSearchField('Select Field');
            setSearchFieldSub('Select Field');
            setDisableSearch(false);
          }}
        >
          Clear Filter
          <CloseCircleOutlined />
        </Button>
      ) : null}
    </div>
  );

  const handleSearch = (e: any) => {
    setPage(0);
    setPageSize(10);
    queryClient.invalidateQueries('uploadHistory');
    const encodedValue = encodeURIComponent(e.trim());
    setFilters(`${searchField}=${encodedValue}`);
  };

  return (
    <>
      {contextHolder}
      <Row gutter={20}>
        <Col xl={24}>
          <Card
            title="Uploads History"
            style={{ marginBottom: 22 }}
            extra={
              <Button type="primary" onClick={() => recallRecentUploads()}>
                Refresh
              </Button>
            }
          >
            <div className="search-bar">
              <Search
                addonBefore={selectBefore}
                placeholder="Search"
                enterButton="Search"
                size="large"
                allowClear
                className={`${disableSearch ? 'disabled' : ''}`}
                disabled={disableSearch}
                onSearch={(e) => handleSearch(e)}
              />
            </div>
            <br />
            <Table
              columns={columns}
              id="recent-uploads"
              rowKey={(record) => record.id!}
              dataSource={recentUploads?.data}
              loading={isLoading}
              onChange={handleChange}
              locale={{
                emptyText: <Empty description="No Uploads" />,
              }}
              pagination={{
                defaultPageSize: 10,
                pageSize,
                current: page + 1,
                total: Number(recentUploads?.headers['x-total-count']),
                pageSizeOptions: ['10', '20', '50', '100'],
                showSizeChanger: true,
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CSVUploads;
